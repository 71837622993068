import { extendTheme } from "@chakra-ui/react";

const colors = {
	background: "#000", // Colore di background
	text: "#ffffff", // Colore del testo
	primary: "#ffce00", // Colore principale
	componentBg: "#1a1a1a", // Background dei componenti
	mutedText: "#828d94", // Testo più spento
	lightText: "#999999", // Testo più spento
	darkBg: "#0f0f0f", // Background più scuro
	borderColor: "#1f1f1f",
	purplePrimary: "#8781ed",
	yellowPrimary: "#FFF970",
	azurePrimary: "#6dc8e9",
};
const breakpoints = {
	base: "0em", // 0px
	sm: "480px", // ~480px. em is a relative unit and is dependant on the font-size.
	md: "768px", // ~768px
	lg: "992px", // ~992px
	xl: "1280px", // ~1280px
	"1.5xl": "1400px", // ~1440px
	"2xl": "1536px", // ~1536px
};
const theme = extendTheme({
	breakpoints,
	fonts: {
		heading: "'Oswald', sans-serif",
		body: "'Pancetta', sans-serif",
	},
	colors: {
		black: colors.background,
		white: colors.text,
		primary: colors.primary,
		purplePrimary: colors.purplePrimary,
		yellowPrimary: colors.yellowPrimary,
		azurePrimary: colors.azurePrimary,
		componentBg: colors.componentBg,
		mutedText: colors.mutedText,
		lightText: colors.lightText,
		darkBg: colors.darkBg,
		borderColor: colors.borderColor,
	},
	styles: {
		global: {
			body: {
				bg: colors.background,
				color: colors.text,
			},
		},
	},
	components: {
		Heading: {
			baseStyle: {
				fontWeight: "normal",
			},
		},
		Button: {
			baseStyle: {
				bg: colors.componentBg,
				color: colors.text,
			},
		},
		// Puoi aggiungere altri componenti qui
	},
});

export default theme;
